import React from 'react';
import { Animated } from 'react-native';
export var createCollapsibleCustomHeaderAnimator = function (customHeader) { return function (headerProps) {
    var _a, _b, _c;
    return (<Animated.View style={(_c = (_b = (_a = headerProps === null || headerProps === void 0 ? void 0 : headerProps.scene) === null || _a === void 0 ? void 0 : _a.descriptor) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.headerStyle} onLayout={function (e) {
        headerProps.navigation.setParams({
            collapsibleCustomHeaderHeight: e.nativeEvent.layout.height,
        });
    }}>
    {customHeader(headerProps)}
  </Animated.View>);
}; };
